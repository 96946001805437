.App-logo {
  height: 4rem;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  backdrop-filter: blur(2px);
  position: sticky;
  gap: 0.5rem;
}

.navButton {
  font-weight: 400;
}

.themeToggleButton {
  width: 4rem;
  height: auto;
}

.moonIcon,
.sunIcon {
  width: 50%;
  height: auto;
  background-color: rgba(177, 212, 224, 0.3);
  border-radius: 0.5rem;
  padding: 0.25rem;
}


/* Media Queries */

/* Tablet */
@media (min-width:600px) {
  .navButton {
    font-size: 1.50rem;
  }

  .App-logo {
    height: 6rem;
  }
}


/* Desktop */
@media (min-width:960px) {
  .navButton {
    font-size: 1.75rem;
  }

  .App-logo {
    height: 8rem;
  }
}