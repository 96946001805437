:root {
  font-family: "Rubik", serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.5;
  font-weight: 300;

  /* color-scheme: light; */
  background-color: rgb(248, 249, 250);
  color: rgb(26, 32, 44);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}