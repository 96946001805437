/* Light theme colours */
.lightTheme {
  background-color: rgb(248, 249, 250);
  color:  rgb(26, 32, 44);
}

/* Dark theme colours */
.darkTheme {
  background-color: rgb(26, 32, 44);
  color: rgb(248, 249, 250);
}

.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.App-header {
  height: 10rem;
  min-width: 320px;

}

.App-main {
  margin: auto 2rem;

}

.App-footer {
  position: sticky;
  height: 12rem;
  border-top: 1px solid rgb(226, 232, 240);

}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.link {
  color: rgb(10, 102, 194);
  font-weight: 600;
}

/* Media Queries */

/* Tablet */
@media (min-width:600px) {}

/* Desktop */
@media (min-width:960px) {}