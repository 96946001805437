.intro {
    margin-bottom: 3rem;
}

.introduction {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: 1rem;
}

.info {
    font-size: 1rem;
}

.projectsCardsContainter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.projectCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    /* box-shadow: 0px 15px 30px -11px rgb(221, 221, 221); */
    border: 1px solid rgb(235, 235, 235);
    box-shadow: 0 2px rgba(161, 161, 161, .2);
    border-radius: 0.5rem;
}

a:hover {
    cursor: pointer;
}

.projectCardImage {
    width: 60vw;
    height: auto;
    padding-top: 1rem;
}

.projectCardInfo {
    display: grid;
    grid-template-rows: 2rem 3.5rem 5rem 3rem;
    padding: 0.5rem;
    gap: 0.5rem;
}

.projectTitle {
    font-size: 1.5rem;
    margin: 0;
}

.tech {
    color: rgb(128, 128, 128);
    font-size: 1.125rem;
    margin: 0;

}

.projectCardDescription {
    margin: 0;
}

.projectLinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;

}

.gitHubButton,
.LivePreviewButton {
    margin: 0;
}

.gitHubIcon,
.linkIcon {
    height: 1rem;
    width: 1rem;
}

/* Media Queries */

/* Tablet */
@media (min-width:600px) {
    .intro {
        margin-top: 4rem;
    }

    .projectsCardsContainter {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 100vw;
    }

    .projectCard {
        max-width: 40vw;
    }

    .projectCardImage {
        width: 40vw;
        height: auto;

    }

    .projectCardInfo {
        grid-template-rows: 4rem 3.5rem 5rem 3rem;

    }
}

/* Desktop */
@media (min-width:960px) {}