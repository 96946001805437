.pageTitle {
    text-align: center;
}

.bioContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.profilePicture {
    width: 70%;
    height: 70%;
    margin-bottom: 1rem;
    border-radius: 15rem;
}

.bio {
    margin: 0.5rem 0;
    text-align: justify;
}

a {
    text-decoration: none;

}

.signature {
    font-family: 'Pacifico', cursive;
    font-size: 1.25rem;
}

.techStackTitle {
    text-align: center;
}

.techStackContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    min-width: 18rem;
}

.html,
.css,
.javaScript,
.sass,
.react,
.redux,
.typescript,
.node,
.next,
.git,
.vsCode,
.canva {
    height: 3rem;
    width: 3rem;
}


/* Media Queries */

/* Tablet */
@media (min-width:600px) {

    .pageTitle {
        padding-top: 1.5rem;
    }

    .bioContainer {
        flex-direction: row;
        align-items: start;
        gap: 1.5rem;
        padding: 2rem 0;
    }

    .profilePicture {
        width: 40%;
        height: 40%;
        align-self: center;

    }

    .personalInfo {
        padding: 0 1rem;

    }

}

/* Media queries */
@media (min-width:600px) {
    .techStackContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width:960px) {
    .profilePicture {
        width: 20%;
        height: 20%;

    }
}